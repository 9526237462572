const Vue = require('vue')

module.exports = function () {
	if (document.querySelector('#vue-contact')) {
		new Vue({
			el: '#vue-contact',
			data: {
				selected: null
			}
		})
	}
}
