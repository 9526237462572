const Bugsnag = require('@bugsnag/js')

Bugsnag.start({
	apiKey: process.env.BUGSNAG_API_KEY,
	releaseStage: process.env.APP_ENV,
	enabledReleaseStages: ['production', 'staging'],
	onError: function (event) {
		if (event.originalError.name === 'TypeError' && (event.originalError.message === 'Illegal invocation' || event.originalError.message.includes('Object.prototype.hasOwnProperty.call(o,"telephone")') || event.originalError.message.includes('Object.prototype.hasOwnProperty.call(e,"telephone")'))) {
			return false
		}
	}
})

if (typeof window.queueMicrotask !== 'function') {
	window.queueMicrotask = function (callback) {
		Promise.resolve()
			.then(callback)
			.catch((e) => setTimeout(() => {
				throw e
			}))
	}
}

require('picturefill')
require('lazysizes')
require('lazysizes/plugins/bgset/ls.bgset')
require('lazysizes/plugins/parent-fit/ls.parent-fit')
require('svg4everybody')()

const Helpers = {
	'Header': require('./helpers/header.js'),
	'Contact': require('./helpers/contact.js')
}

new Helpers.Header()
new Helpers.Contact()
